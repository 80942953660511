import React, { Fragment, useEffect } from 'react';
import './App.scss';
import { Routes, Route, useNavigate } from 'react-router-dom';
import Header from './Layouts/header';
import routeItems from './Routes/routes';
import Notfound from './Components/Not-found';
import Sidebar from './Layouts/sidebar';

function App() {
  const isLogged = !!localStorage.getItem('userAuthToken')
  const navigate = useNavigate();

  const [scriptLoaded, setScriptLoaded] = React.useState(false);

  // language translation script
  useEffect(() => {
    const googleTranslateElementInit = () => {
      try {
        new window.google.translate.TranslateElement(
          {
            // pageLanguage: 'en',
            // multilanguagePage: true,
            autoDisplay: false,
            // includedLanguages: 'en,es,pt,it,de,fr',
          },
          'google_translate_element'
        );
        setTimeout(() => {
          const selectElement = document.querySelector('.goog-te-combo');
          if (selectElement) {
            selectElement.value = 'en';
            selectElement.dispatchEvent(new Event('change'));
          }
        }, 1000);
        setTimeout(() => {
          const skipTranslateElement = document.querySelector('.skiptranslate');
          if (skipTranslateElement !== undefined) {
            skipTranslateElement.style.display = 'none';
            skipTranslateElement.style.visibility = 'hidden';
          }
        }, 2000);
      } catch (error) {
        console.error('Error during Google Translate initialization:', error);
      }
    };

    window.googleTranslateElementInit = googleTranslateElementInit;

    const addGoogleTranslateScript = () => {
      if (!document.querySelector('script[src="//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"]')) {
        const script = document.createElement('script');
        script.src = '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
        script.async = true;
        script.onload = () => {
          setScriptLoaded(true);
        };
        script.onerror = () => {
          console.error('Google Translate script failed to load.');
        };
        document.body.appendChild(script);
      } else {
        setScriptLoaded(true);
      }
    };

    if (!scriptLoaded) {
      addGoogleTranslateScript();
    } else if (window.google && window.google.translate && window.google.translate.TranslateElement) {
      googleTranslateElementInit();
    }

  }, [scriptLoaded]);

  // useEffect(() => {
  //   if (isLogged) {
  //     navigate('/')
  //   }
  // }, [])

  return (
    <div >
      <Routes>
        {
          routeItems?.map(el => {
            if (el.layout === 'mainLayout') {
              return (
                <Fragment key={el.path}>
                  <Route element={<Header />} >
                    <Route path={el.path} element={el.component} />
                  </Route>
                </Fragment>
              )
            }
          })
        }
        {
          routeItems?.map(el => {
            if (el.layout === 'sidebarLayout') {
              return (
                <Fragment key={el.path}>
                  <Route element={isLogged ? <Sidebar /> : null} >
                    <Route path={el.path} element={el.component} />
                  </Route>
                </Fragment>
              )
            }
          })
        }
        <Route path='*' element={<Notfound />} />
      </Routes>

    </div>
  );
}

export default App;
